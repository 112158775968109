import {storyblokEditable} from '@storyblok/react'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import {HeroSectionStoryblok} from 'types/storyblok-types'
import linkTypeChecker from 'util/linkTypeChecker'

type HeroContainer = {
  blok: HeroSectionStoryblok
  sectionAfterRoundedHero: boolean
}

type HeroSection = {
  title: string
  description: string
  button_label?: string
  button_link?: string
  bottom_overlap?: boolean
  background_image_url: string
}

export const HeroSection = ({
  title = 'HeroTitle',
  description = 'description',
  button_label,
  button_link,
  bottom_overlap,
  background_image_url = '',
}: HeroSection) => {
  return (
    <div className="w-full max-w-[1366px]">
      <Image
        src={background_image_url}
        alt={title}
        fill
        className="-z-10 object-cover"
      />
      <div className="absolute left-0 top-0 -z-10 size-full bg-gradient-to-b from-black to-transparent md:w-8/12 md:bg-gradient-to-r"></div>
      <h1 className="max-w-lg font-rubik text-[32px] font-bold leading-[50px] text-dats-a1 md:text-[46px]">
        {title}
      </h1>
      <p className="mt-7 max-w-lg text-lg text-dats-a1">{description}</p>
      {button_link && button_label ? (
        <Link
          href={button_link}
          className="mt-8 inline-block w-auto text-dats-a1 no-underline"
        >
          <button type="button" className="flex items-center">
            <span className="text-base">{button_label}</span>
            <span className="ml-6">
              <svg width="35" height="36" viewBox="0 0 35 36" fill="none">
                <rect
                  opacity="0.15"
                  y="0.78125"
                  width="35"
                  height="35"
                  rx="5"
                  fill="white"
                />
                <path
                  d="M21.1347 19.0764C21.5176 18.6385 21.5176 17.9274 21.1347 17.4894L15.253 10.7631C14.8701 10.3251 14.2482 10.3251 13.8653 10.7631C13.4824 11.201 13.4824 11.9122 13.8653 12.3501L19.0547 18.2847L13.8684 24.2193C13.4855 24.6572 13.4855 25.3684 13.8684 25.8063C14.2513 26.2442 14.8732 26.2442 15.2561 25.8063L21.1377 19.08L21.1347 19.0764Z"
                  fill="#F0FCFF"
                />
              </svg>
            </span>
          </button>
        </Link>
      ) : null}
      {bottom_overlap ? (
        <div className="absolute bottom-0 left-0 -z-10 h-12 w-full overflow-hidden rounded-t-dats-xl bg-white md:left-1/2 md:h-11 md:-translate-x-1/2 md:rounded-t-[50px]"></div>
      ) : null}
    </div>
  )
}

const HeroContainer = ({blok, sectionAfterRoundedHero}: HeroContainer) => {
  const sectionAfterRoundedHeroClass = classNames({
    'rounded-dats-xl md:rounded-t-[50px] -mt-11': sectionAfterRoundedHero,
  })
  return (
    <section
      className={`relative flex justify-center px-4 ${
        blok.bottom_rounded_overlay ? '-mb-11 pb-28 pt-16' : 'py-16'
      } lg:px-[100px] ${sectionAfterRoundedHeroClass}`}
      {...storyblokEditable(blok)}
    >
      <HeroSection
        title={blok.title}
        background_image_url={blok.background.filename}
        button_label={blok.button_label}
        button_link={linkTypeChecker(blok.button_link)}
        bottom_overlap={blok.bottom_rounded_overlay}
        description={blok.description}
      />
    </section>
  )
}

export default HeroContainer
